export const environment = {
  production: true,
  cdnUrl: 'https://cdn.burgerschapskaarten.nl/',
  apiUrl: 'https://api.burgerschapskaarten.nl/',
  frontUrl: 'https://www.burgerschapskaarten.nl/',
  portalUrl: 'https://portal.methodem.nl/',
  systemKey: 'burgerschapskaarten',
  salesWebsite: 'https://over.burgerschapskaarten.nl/',
  contactEmail: 'info@burgerschapskaarten.nl',
  contactPhone: '0031003333333',
  // title: 'Burgerschapskaarten',
  // slogan: 'Burgerschapskaarten',
  // description: 'Kom, we gaan samen aan de slag met maatschappijleer! Methode M is actueel, interactief en vernieuwend.',
  frontStyle: 'method',
  frontModule: 'burgerschap-basisonderwijs',
  trackingId: 1
};